import React, { useState, useEffect } from "react";
import { EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

// import Get from "../components/Get";
import "../App.css";
import axios from "axios";
// import htmlToDraft from "html-to-draftjs";

const HomePage = ({ type }) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [editorValue, setEditorValue] = useState("");
  const [editorValue2, setEditorValue2] = useState("");
  // const [getdbvalue, setgetdbvalue] = useState("");

  const content = JSON.stringify(editorValue);
  const recapcontent = JSON.stringify(editorValue2);
  const [details, setDetails] = useState({
    SubjectTitle: "",
    VideoTitle: "",
  });

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };
  useEffect(() => {
    const draft = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    setEditorValue(draft);
  }, [editorState]);

  const onchange = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };
  // console.log(editorValue)

  const inp = document.querySelectorAll("input");
    const edi = document.querySelector(".notranslate");

    console.log(details.title)
  const submit = async (e) => {
    e.preventDefault();
    console.log(123)

    const body = {
      type: details.SubjectTitle,
      SectionTitleName: details.VideoTitle,
      content: content,
      title:details.title
    };

      //post data to testDB

        await axios.post(
          `https://server.techiepanda.in/project/addProject`,
          {
            ...body,
          }
        ).then(res=>{
            edi.innerHTML = "";
            setEditorValue("");
        })

      


    
    // inp.forEach((e) => {
    //   e.value = "";
    // });
   
    // setEditorValue2("");
  };

  // const edi = document.querySelector(".notranslate");
  // if (getdbvalue) {
  //   const editordiv = document.querySelector(".notranslate");
  //   let parser = JSON.parse(getdbvalue.content);
  //   edi.innerHTML = parser;
  //   console.log(editordiv.children[0].children);
  // }

  return (
    <div className="editor">
      <div className="input">
        <span>Type</span>
        <input
          placeholder="HTML , CSS , BootStrap"
          onChange={(e) => onchange(e)}
          name="SubjectTitle"
        />

        <span>SectionTitleName</span>
        <input
          placeholder="Titles"
          onChange={(e) => onchange(e)}
          name="VideoTitle"
        />


        <span>Project Title</span>
        <input
          placeholder="Project Title"
          onChange={(e) => onchange(e)}
          name="title"
        />
      </div>
      {/* <div className="editors">
        <Recap editorValue2 = {editorValue2} setEditorValue2= {setEditorValue2} />
      <div>
        <h1>Enter the content in this Editor below</h1>  */}
      <Editor
        editorState={editorState}
        wrapperClassName="wrapper-class"
        editorClassName="editor-class"
        toolbarClassName="toolbar-class"
        onEditorStateChange={onEditorStateChange}
      />
      {/* </div>
      </div> */}
      <div className="buttonsDiv">
        {/* <Get
          details={{ ...details, content: content }}
          setgetdbvalue={setgetdbvalue}
        />
        <Update
          details={{ ...details, content: content }}
          setEditorValue={setEditorValue}
          setgetdbvalue={setgetdbvalue}
        /> */}
        <button onClick={submit}>Submit</button>
      </div>
    </div>
  );
};

export default HomePage;